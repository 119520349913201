<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <h3 class="text-2xl my-4">Crear notificación</h3>
        <div class="flex">
            <div class="w-full md:w-4/5 lg:w-2/3 xl:w-1/2">
                <form @submit.prevent="createNotification()" @keydown="removeError($event.target.id)">
                    <a-card>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full">

                                <a-input
                                    required
                                    label="Título"
                                    id="title"
                                    :error="error('title')"
                                    v-model="notification.title" />

                                <a-input
                                    class="mt-4"
                                    required
                                    type="textarea"
                                    label="Contenido"
                                    id="content"
                                    :error="error('content')"
                                    v-model="notification.content" />

                                <a-select
                                    class="mt-4"
                                    required
                                    label="Destinatarios"
                                    v-model="notification.type"
                                    :disabled="loadings.courses"
                                    :source="notificationTypes"
                                    :error="error('type')" />

                                <a-select
                                    class="mt-4"
                                    v-if="notification.type === 7"
                                    label="Usuario: "
                                    :source="mappedUsers"
                                    v-model="notification.receiver_user_id"
                                    :error="error('receiver_user_id')" />

                                <a-select
                                    class="mt-4"
                                    required
                                    v-if="notification.type === 8"
                                    :source="courseSections.map($0 => ({ label: $0.specialization, value: $0.section_id }))"
                                    v-model="section"
                                    label="Sección "/>

                                <a-select
                                    class="mt-4"
                                    required
                                    v-if="notification.type === 9"
                                    :source="courseSections"
                                    v-model="course"
                                    label="Materia/Sección "/>

                                <a-upload-input
                                    class="mt-4"
                                    label="Archivo"
                                    id="attachment"
                                    :error="error('attachment')"
                                    v-model="notification.attachment"/>
                            </div>
                        </div>
                    </a-card>

                    <div class="flex flex-wrap space-x-2 mt-4">
                        <a-button type="submit" :loading="loading">
                            Enviar
                        </a-button>

                        <a-button outlined :to="{ name: 'notifications' }">
                            Cancelar
                        </a-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { notificationTypes } from '@/constants'
export default {
    data: () => ({
        notification: {
            content: '',
        },
        loadings: {
          courses: false
        },
        currentPeriodId: 0,
        courseSections: {},
        course: null,
        section: null,
        activeCourse: {},
        activeSection: {}
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            loading: state => state.notifications.loading,
            user: ({ session }) => session.user,
            users: state => state.users.all,
            period: state => state.periods.all
        }),
        notificationTypes() {
            return Object.values(notificationTypes)
                .flatMap($0 => {
                    if (this.user.isAdminGroup) {
                      if ($0.value < 8) {
                        return { label: $0.description, value: $0.value}
                      } else {
                        return []
                      }
                    } else {
                      return { label: $0.description, value: $0.value }
                    }
                })
        },
        mappedUsers() {
            return this.users.map($0 => ({ email: $0.email, label: $0.name, value: $0.id }))
        },
    },
    watch: {
        course (value) {
            this.section = null
            this.courseSections.forEach((course) => {
                if (parseInt(course.value) === value) {
                  this.activeCourse = course
                }
            })
        },
        section (value) {
            this.course = null
            this.activeSection = value
        },
        'notification.type' () {
            this.course = null
            this.activeCourse = null
            this.section = null
        }
    },
    methods: {
        ...mapActions({
            store: 'notifications/store',
            removeError: 'removeError',
            fetchUsers: 'users/fetchUsers',
            fetchPeriods: 'periods/index'
        }),
        createNotification () {
            if (this.notification.type === 8) {
                this.store({ ...this.notification, section_id: this.section })
                // console.log({ ...this.notification, section_id: this.section })
            } else if (this.notification.type === 9) {
                // console.log({ ...this.notification, section_id: this.activeCourse.section_id, course_id: this.activeCourse.course_id })
                this.store({ ...this.notification, section_id: this.activeCourse.section_id, course_id: this.activeCourse.course_id })
            } else {
                // console.log({ ...this.notification })
                this.store({ ...this.notification })
            }
        }
    },
    mounted() {
        this.loadings.courses = true
        this.fetchUsers({ limit: 0, select: 'name,email' })
            this.fetchPeriods({ limit: 0 })
                .then((response) => {
                    response.forEach((period) => {
                        if (period.enabled) {
                            this.currentPeriodId = period.id
                        }
                    })
                })
                .then(() => {
                    if(this.user.isTeacherGroup) {
                        this.$repository.teachers
                            .courses(this.user.teacher.id, { period: this.currentPeriodId, limit: 0, with: 'lessons' })
                            .then(({ data }) => {
                                this.courseSections = data.map(({ course_id, section_id, section: { letter_code }, course: { title }, id, specialization }) => ({
                                  label: `${title}, Sección ${letter_code}`,
                                  value: id,
                                  course_id,
                                  section_id,
                                  specialization: `${specialization.title}, Sección ${letter_code}`,
                                }))
                            })
                            .finally(() => {
                              this.loadings.courses = false
                            })
                    } else {
                      this.loadings.courses = false
                    }
                })
    }
}
</script>
